import { CacheLocation } from '@auth0/auth0-spa-js';

export const environment = {
    production: false,
    backendServerUrl: 'https://dev.stores.api.statusnow.com',
    questionnaireUrl: '',
    appInsightsKey: '74db032f-3be9-442a-82c3-f58a5ad477bb',
    auth: {
        domain: "auth-store.statusnow.com",
        clientId: "eUXxpceYK7Uuo27DgEoxNVa9oiudUN3c",
        authorizationParams: {
            audience: 'https://dev.stores.statusnow.com',
            connection: 'email',
            redirect_uri: "https://dev.stores.statusnow.com",
        },
        cacheLocation: ('localstorage' as CacheLocation),
        useRefreshTokens: true,
        useRefreshTokensFallback: true,

        // The AuthHttpInterceptor configuration
        httpInterceptor: {
            allowedList: [
                {
                    uri: 'https://dev.stores.api.statusnow.com/*',
                    tokenOptions: {
                        authorizationParams: { scope: "openid profile email" }
                    }
                }
            ]
        },
    },
};
